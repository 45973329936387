<template>
  <div class="phone">
   <a-phone-input
      ref="phoneInput"
      v-model="phone"
      :input-width="inputWidth"
      :readonly="readonly"
      :required="required"
      :error-message="$t('signUp.errors.phoneBlank')"
      :helper-message="$t('shared.helperPhoneMessage')"
      :show-error="showValidationMessage && !phone?.phoneNumber?.length"
      :aria-label="$t('shared.phoneNumber')"
      :input-label="$t('shared.phoneNumber')"
      class="input-username-phone"
      @change="onPhoneNumberChange"
    />
  </div>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';

export default {
  name: "ReactivePhoneInput",
  props: {
    value: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    validatePhone: {
      type: Boolean,
      default: false,
    },
    inputWidth: {
      type: String,
      default: '240px',
    },
  },
  data() {
    return {
      phone: null,
      showValidationMessage: false,
    }
  },
  beforeMount() {
    const pn = parsePhoneNumber( this.value )
    this.phone = {
      phoneNumber: pn.number.significant,
      regionCode: pn.regionCode,
      countryCode: pn.countryCode,
    }
  },
  mounted() {
    this.$refs.phoneInput.$el.querySelector('input').addEventListener('invalid', this.handleInputInvalid);
  },
  beforeDestroy() {
    this.$refs.phoneInput.$el.querySelector('input').removeEventListener('invalid', this.handleInputInvalid);
  },
  methods: {
    handleInputInvalid(e) {
      e.preventDefault();
      this.showValidationMessage = true;
    },
    onPhoneNumberChange(phone) {
      // Since 5.3.1 of awesome-phonenumber, "possible" is broken and doesnt allow
      // fake phone numbers of correct length for a given region.
      // Instead, if we want "possible" only, set validatePhone to false and check if the component's mask
      // is completely filled. The e164 vaalue will still emit correctly.
      const pn = parsePhoneNumber( `${phone.phoneNumber}`, { regionCode: phone.regionCode } )
      const valid = this.validatePhone ? pn.valid : true

      if (this.$refs.phoneInput.maskObject.completed && valid) {
        this.$emit('input', pn.number.e164)
      } else {
        this.$emit('input', null)
      }
    },
  },
}
</script>
